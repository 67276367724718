<template>
  <panel
    :title="title"
    :breadcrumbs="breadcrumbs"
  >
    <v-card>
      <v-alert
        :value="true"
        color="info"
        icon="info"
      >
        No payment will be made for this incentive. <br>
        The displayed Total Black Ai is shown only for the purpose of calculating the target KPI.
      </v-alert>
      <v-toolbar
        style="height: 80px; padding: 16px;"
        card
      >
        <v-select
          v-model="year"
          :items="years"
          label="Year"
          prepend-inner-icon="event"
        />
        <v-spacer />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :pagination.sync="pagination"
        class="px-1 pb-1"
        item-key="msisdn"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="headerClass(header)"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.month }}
          </td>
          <td class="text-xs-left">
            RM {{ props.item.payout }}
          </td>
          <td class="text-xs-left">
            RM {{ props.item.totalAi }}
          </td>
          <td class="text-xs-center">
            RM {{ props.item.total }}
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const pageTitle = 'Target KPI'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      menu: false,
      year: null,
      years: [],
      monthOf: null,
      maxDate: '',
      title: pageTitle,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      items: [],
      loading: false,
      search: '',
      totalItems: 0,
      pagination: {},
      error: null,
    }
  },
  computed: {
    headers: function () {
      const headers = [
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Month',
          value: 'month',
        },
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Payout',
          value: 'payout',
        },
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Total Black Ai',
          value: 'totalAi',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Total',
          value: 'total',
        },
      ]
      return headers
    },
  },
  watch: {
    year: function (val) {
      this.getErechargeTarget(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2024; year--) {
      this.years.push(year)
    }
    this.year = maxDate
  },

  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getErechargeTarget (year) {
      this.loading = true
      const params = createGetParams({
        year: year,
      })
      this.$rest.get('getErechargeTarget.php', params)
        .then((response) => {
          this.items = response.data.items
          this.noDataText = "There's nothing to show."
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.noDataText = error.message + ': ' + error.response.data
          this.loading = false
        })
    },
    headerClass (header) {
      const headerClass = ['column']
      header.align && headerClass.push(header.align)
      header.sortable && headerClass.push('sortable')
      header.sortable && (this.pagination.descending
        ? headerClass.push('desc') : headerClass.push('asc'))
      header.sortable && header.value === this.pagination.sortBy &&
          headerClass.push('active')

      return headerClass
    },
  },
}
</script>
